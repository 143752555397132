export enum ClientSubCategory {
  NewLead = 1,
  ReturningLead = 2,
  OneTimeCustomer = 3,
  ReturningCustomer = 4,
  VipCustomer = 5,
}

export enum ClientCategory {
  Lead = 1,
  Customer = 2,
}